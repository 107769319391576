import './Header.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ViewportContext } from '../../contexts/ViewportContext';
import { useNavigate } from 'react-router-dom';
import { ReservaLogo as Logo } from '../../assets/logo';
import { HeaderHelpIcon, HeaderLogoutIcon } from '../../assets/icons';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import { getAuthorizationState, getRejectedFrom, setRejectedFrom } from '../../AppSlice';
import { useAuth } from "../../Auth";
import AppModal from '../AppModal/AppModal';
import { fetchBuildVersion, getBuildVersion } from '../../store/versionSlice';
import { useAppDispatch } from '../../store';

interface HeaderProps {
    showHelp: boolean;
}

const Header: FC<HeaderProps> = ({ showHelp }) => {

    const SUPPORT_PORTAL_LINK = "https://support.uniguest.com";

    const { isMobile } = useContext(ViewportContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authState = useAppSelector(getAuthorizationState);
    const rejectedFrom = useAppSelector(getRejectedFrom);
    const auth = (useAuth() as any); // TODO: avoids type warning as Auth.js isn't typescript. Fix by converting to TS.
    const [openModal, setOpenModal] = useState(false);
    const buildVersion = useAppSelector(getBuildVersion);
    const [isPreRelease, setPreReleaseStatus] = useState(false);

    const populateBuildVersion = useCallback(() => {
        dispatch(fetchBuildVersion());
    }, [dispatch]);

    useEffect(() => {
        populateBuildVersion();
    }, [populateBuildVersion]);


    useEffect(() => {
        const temp = buildVersion.split('-');
        setPreReleaseStatus(temp.length === 3);
    }, [buildVersion]);

    const logout = () => {
        auth.signout();
        if (authState.identity_provider === 'aad' || rejectedFrom === 'aad') {
            if (rejectedFrom === 'aad') {
                dispatch(setRejectedFrom(''));
            }

            window.location.assign("/.auth/logout?post_logout_redirect_uri=/");
        } else {
            navigate('/login');
        }
    };

    const HelpDropdown = () => {
        return <Dropdown>
            <Dropdown.Toggle variant="success" id="help-dropdown">
                <HeaderHelpIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href={SUPPORT_PORTAL_LINK} target={"_blank"}>Support Portal</Dropdown.Item>
                <Dropdown.Item onClick={() => setOpenModal(true)}>About</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>;
    };

    const DesktopHeader = () => <header>

        {showHelp ?
            <AppModal
                show={openModal}
                className={"reserva-version-modal"}
                header={<><Logo />
                    {!isPreRelease && <div>Version {buildVersion} </div>}
                    {isPreRelease && <div>Version {buildVersion} TEST ONLY</div>}</>}
                content={
                    <div>
                        <div className="d-flex flex-column userinfo">
                            <div className="username">{authState?.name} ({authState?.username})</div>
                            <div className="useremail">{authState?.email}</div>
                        </div>
                        <div>
                            Support portal for Reserva Booking Portal is available on the web at the <a target={"_blank"} rel="noreferrer" href={SUPPORT_PORTAL_LINK}>Uniguest Customer Portal</a>.
                        </div>
                    </div>
                }
                footer={<div><Button className={"btn-solid"} onClick={() => setOpenModal(false)}>Close</Button></div>}

            />
            : <></>}

        <div className={"header-content"}>
            <div>
                <Logo className={"cursor-pointer logo"} />
            </div>
            <div className={"reserva-header-tools"}>
                {showHelp ? <HelpDropdown /> : <></>}
                <OverlayTrigger
                    placement={'bottom'}
                    key={"Logout"}
                    overlay={<Tooltip className={"sidenav-tooltip"} id="button-tooltip">Log out: {authState.username}</Tooltip>}
                >
                    <button onClick={() => logout()} >
                        <HeaderLogoutIcon />
                    </button>
                </OverlayTrigger>
            </div>
        </div>
    </header >;

    const MobileHeader = () => <header>
        <div className={"header-content mobile"}>
            <div>
                <Logo className={"cursor-pointer logo"} />
            </div>

            <div className={"reserva-header-tools"}>
                <button>
                    <HeaderLogoutIcon />
                </button>
            </div>
        </div>
    </header>;

    return (isMobile ? MobileHeader() : DesktopHeader());
};

export default Header;
