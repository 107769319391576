import './LicenceVerification.scss';
import Spinner from "../../components/Spinner/Spinner";
import { fetchIsLicensed, getFetchIsLicensedComplete, getIsLicensed } from '../../store/licenceSlice';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';

export const RequireLicence = ({ children }) => {
    const dispatch = useDispatch();
    const isLicensed = useSelector(getIsLicensed);
    const verificationCompleted = useSelector(getFetchIsLicensedComplete);

    if (!verificationCompleted) {
        dispatch(fetchIsLicensed()).then(r => {
            if (r.error) {
                dispatch(fetchIsLicensed());
            }
        });
    }

    const Message = () => {
        if (!verificationCompleted) {
            return <p>Verifying Booking Portal licence...</p>;
        }

        if (isLicensed === true) {
            return <p>Verification complete.</p>;
        } else {
            return <p>You do not have a valid Booking Portal licence.</p>;
        }
    };

    if (verificationCompleted && isLicensed) {
        return children;
    } else {
        return (<>
            <Header showHelp={false} />
            <div className={"reserva-pw-verification-container"}>
                <div className="reserva-pw-verification">
                    {!verificationCompleted ? <Spinner /> : <></>}
                    <Message />
                </div>
            </div>
        </>
        );
    }
};