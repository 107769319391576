import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux';
import desksReducer from "./desksSlice";
import groupsReducer from "./groupsSlice";
import bookingsReducer from "./bookingsSlice";
import licenceReducer from './licenceSlice';
import profilesReducer from './profilesSlice';
import versionReducer from './versionSlice';
import appReducer from "../AppSlice";

export const store = configureStore({
    reducer: {
        app: appReducer,
        version: versionReducer,
        desks: desksReducer,
        groups: groupsReducer,
        licence: licenceReducer,
        bookings: bookingsReducer,
        profiles: profilesReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();