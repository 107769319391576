import { BookingSettings, Desk, DeskStatus } from "../types";
import { getWorkHours, getWorkHoursIso, getTimeZone } from "./bookingUtils";
import { checkIntervalOverlap, convertMinutesToHHMM, createDateObject, getCurrentDate,
    getCurrentTime, getIntervalInMinutes, convert24hTo12h, formatTime24H, isSameDay, formatDateToDDMMYYYY
 } from "./datetimeUtils";

const getDeskStatus = (bookingOptions: BookingSettings, desk: Desk, ignoredBookingIds?: string[]): DeskStatus => {

    const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    // Check if target period fits within desk working hours.
    const bookingSettings = bookingOptions;
    let targetIntervalStart: Date = createDateObject(bookingSettings.date, bookingSettings.startTime) ?? new Date();
    let targetIntervalEnd: Date = createDateObject(bookingSettings.date, bookingSettings.endTime) ?? new Date();

    const targetIntervalWeekday = weekdays[targetIntervalStart.getDay()];

    const deskWorkingHours = getWorkHours(desk);

    const timeZone = getTimeZone(desk);

    const noWorkingHoursForThisDay = Object.keys(deskWorkingHours).length === 0 || deskWorkingHours[targetIntervalWeekday] === undefined;

    const targetIntervalDay = targetIntervalWeekday.toLowerCase();

    // Convert HH:mm work hours to ISO formatted based on date and resource time zone
    const targetWorkingHours = getWorkHoursIso(bookingOptions.date, deskWorkingHours[targetIntervalDay], timeZone);

    let targetIntervalWithinBookingHours = false;

    // Check desk profile rules

    // Check if desk can be booked
    if (!desk.profile.settings.create) {
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            errorMessage: "Unavailable",
            timeZone: timeZone
        };
    }

    // Check maximum booking duration
    const bookingDurationMinutes = getIntervalInMinutes(targetIntervalStart, targetIntervalEnd);
    if (bookingDurationMinutes > desk.profile.settings.maximumExtensionMinutes) {
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            errorMessage: `This desk cannot be booked longer than ${convertMinutesToHHMM(desk.profile.settings.maximumExtensionMinutes)}h`,
            timeZone: timeZone
        };
    }

    // Check maximum days in advance
    const maximumAdvancedBookingDaysMilliseconds = desk.profile.settings.maximumAdvancedBookingDays * 24 * 60 * 60 * 1000;
    if ((targetIntervalStart.getTime() - new Date().getTime()) > maximumAdvancedBookingDaysMilliseconds) {
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            errorMessage: `This desk cannot be booked more than ${desk.profile.settings.maximumAdvancedBookingDays} days in advance.`,
            timeZone: timeZone
        };
    }

    // Check if there are available working hours    
    if (noWorkingHoursForThisDay) {
        return {
            available: false,
            outsideWorkingHours: true,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            timeZone: timeZone
        };
    }

    if (targetWorkingHours && targetWorkingHours.start && targetWorkingHours.end) {

        const workingHoursStart = new Date(targetWorkingHours.start) ?? new Date();
        const workingHoursEnd = new Date(targetWorkingHours.end) ?? new Date();

        const allDayStart = bookingSettings.date === getCurrentDate() ? getCurrentTime(1) : workingHoursStart;

        // If all day option is set, then target interval is the same as the desks' working hours.
        if (bookingSettings.allDay) {
            targetIntervalStart = allDayStart;
            targetIntervalEnd = workingHoursEnd;
        }

        // Determine if target interval is within working hours.
        targetIntervalWithinBookingHours = workingHoursStart <= targetIntervalStart && workingHoursEnd >= targetIntervalEnd &&
                                           targetIntervalStart < targetIntervalEnd;

        // Desk unavailable target interval falls outside set working hours.
        if (!targetIntervalWithinBookingHours)
            return {
                available: false,
                outsideWorkingHours: true,
                bookedBy: '',
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: targetWorkingHours.start,
                endTime: targetWorkingHours.end,
                unknown: false,
                timeZone: timeZone
            };

        // Check if there are any bookings that overlap with the target time interval. Ignore any bookings in ignoredBookingIds.
        const bookingsForTargetInterval = desk.bookings.filter((booking) => {
            return checkIntervalOverlap(targetIntervalStart, targetIntervalEnd, new Date(booking.startTime), new Date(booking.endTime))
                && !ignoredBookingIds?.includes(booking.uid);
        });

        if (bookingSettings.allDay && bookingsForTargetInterval.length === 0)
            return {
                available: true,
                outsideWorkingHours: false,
                bookedBy: "",
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: allDayStart.toISOString(),
                endTime: targetWorkingHours.end,
                unknown: false,
                timeZone: timeZone
            };

        if (bookingsForTargetInterval.length > 0) {

            return {
                available: false,
                outsideWorkingHours: false,
                bookedBy: bookingsForTargetInterval[0].organiser.name,
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: targetWorkingHours.start,
                endTime: targetWorkingHours.end,

                // TODO: startTime/endTime are Date type in Booking interface, so why is new Date(..) required?
                bookedFrom: new Date(bookingsForTargetInterval[0].startTime).toISOString(),
                bookedUntil: new Date(bookingsForTargetInterval[0].endTime).toISOString(),

                unknown: false,
                timeZone: timeZone

            };
        } else {
            // If target interval is within working hours and isn't overlapped by existing bookings, then show desk as available.
            return {
                available: true,
                outsideWorkingHours: false,
                bookedBy: "",
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: targetWorkingHours.start,
                endTime: targetWorkingHours.end,
                unknown: false,
                timeZone: timeZone
            };
        }
    } else {
        // If none of these conditions are met, status is unknown
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: "",
            date: bookingSettings.date.replace("-", "/"),
            startTime: "",
            endTime: "",
            unknown: true,
            timeZone: timeZone
        };
    }

};

function formatDeskHours(from: string, to: string): string {
    // If from/to are same day, just display the times. If not, show dates also

    const fromDate = new Date(from);
    const toDate = new Date(to);

    const fromDateStr = convert24hTo12h(formatTime24H(fromDate));
    const toDateStr = convert24hTo12h(formatTime24H(toDate));

    if (isSameDay(fromDate, toDate)) {
        return `${fromDateStr} - ${toDateStr}`;
    } else {
        return `${formatDateToDDMMYYYY(fromDate)} ${fromDateStr} - ${formatDateToDDMMYYYY(toDate)} ${toDateStr}`;
    }
}

export {
    getDeskStatus,
    formatDeskHours
};