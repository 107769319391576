import { FC, useContext, useState } from "react";
import { Booking, Resource, ViewMode, Option } from "../../types";
import { getDuration } from "../../utils/datetimeUtils";
import { ViewportContext } from "../../contexts/ViewportContext";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import OptionsDropdown from "../OptionsDropdown/OptionsDropdown";
import { formatBookingHours } from "../../utils/bookingUtils";

const SHRINK_TEXT_HEIGHT_THRESHOLD = 20;

interface CalendarCardProps {
    booking: Booking,
    resource: Resource,
    viewMode: ViewMode,
    height?: number,
    heightOffset?: number,
    onOptionSelect: (value: string) => void;
}

export const CalendarCard: FC<CalendarCardProps> = ({ booking, resource, viewMode, height, heightOffset, onOptionSelect }) => {

    const maximumDeskNameLength = 15;
    const maximumUngroupedDeskNameLength = 24;
    const maximumGroupNameLength = 14;

    const startTime = new Date(booking.startTime);
    const endTime = new Date(booking.endTime);

    const { isMobile } = useContext(ViewportContext);
    const groups = resource?.groupNamePath ? resource.groupNamePath?.split(" ->") : '';
    const duration = getDuration(startTime, endTime);
    const compactText = height !== undefined && height < SHRINK_TEXT_HEIGHT_THRESHOLD;

    const [show, setShow] = useState(false);
    const handleOnMouseEnter = () => {
        setShow(true);
    };
    const handleOnMouseLeave = () => {
        setShow(false);
    };

    const options: Option[] = [
        { name: "Edit", value: "edit" },
        { name: "Cancel", value: "cancel" }
    ];

    const formattedGroupString = groups ? groups.reverse().map((group) => {
        let groupName = group;
        if (groupName.length > maximumGroupNameLength)
            groupName = groupName.substring(0, maximumGroupNameLength) + "...";
        return `, ${groupName}`;
    }) : '';

    const getFormattedResourceName = (name: string, grouped: boolean, maxLength: number, maxLengthUngrouped: number) => {
        let resourceName = name;

        if (grouped) {
            if (resourceName.length > maxLength)
                resourceName = resourceName.substring(0, maxLength) + "...";
        } else {
            if (resourceName.length > maxLengthUngrouped)
                resourceName = resourceName.substring(0, maxLengthUngrouped) + "...";
        }

        return resourceName;
    };


    const renderTooltip = (props: any) => {
        const formattedDate = startTime.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        return (
            <Tooltip className={`more-info-tooltip ${isMobile ? "mobile" : ""}`} id="button-tooltip" {...props} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                <div className={"tooltip-header"}>
                    <div className='desk-number-group-container'>
                        <span className="desk-number">{resource?.name}</span>
                        {groups ?
                            <div className={"desk-group-hierarchy"}>
                                <span>{groups[1]}</span>
                                <span>{groups[0]}</span>
                            </div> : <></>}
                    </div>
                    <div className={"btn-options-container"} hidden={new Date(booking.startTime) < new Date()}>
                        <OptionsDropdown bookingId={booking.uid} options={options} onOptionSelect={onOptionSelect} />
                    </div>
                </div>
                <hr />
                <div className={"tooltip-details"}>
                    <span className={"short-date"}>{formattedDate}</span>
                    <div className={"time-interval"}>
                        {formatBookingHours(booking, true)}
                    </div>
                    {/* {resource.issues && resource.issues.length > 0 ?
                        <div className={"issues"}>
                            {resource.issues.map((issue) => <span key={issue} className={"issue"}><span className="error-icon" />{issue}</span>)}
                        </div>
                        : <></>} */}
                </div>
                {/* Unhide to show tags */}
                {/* {booking.resource.tags.length > 0 ?
                    <>
                        <hr />
                        <div className={"tooltip-tags"}>
                            <Tags labels={booking.resource.tags} />
                        </div>
                    </>
                    : <></>} */}
            </Tooltip>
        );
    };

    return (
        <OverlayTrigger placement="auto" show={show} overlay={renderTooltip}>
            <Col className={`calendar-booking-card ${ViewMode[viewMode].toLowerCase()}-view`}
                style={height ? { height: `${height}px`, marginTop: `${heightOffset}px` } : {}}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}>
                <span className={`booking-name ${compactText ? 'compact-text' : ''}`}>
                    {resource ? getFormattedResourceName(resource?.name, groups.length > 0, maximumDeskNameLength, maximumUngroupedDeskNameLength) : ""}
                    {formattedGroupString}
                    {(viewMode === ViewMode.Day || viewMode === ViewMode.Week) ?
                        ` - (${duration} hours)` : ''}
                </span>
                {(viewMode === ViewMode.Month) ?
                    <span className="booking-time">
                        {formatBookingHours(booking)}
                    </span> : <></>
                }
            </Col>
        </OverlayTrigger >
    );
};