import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from 'react-bootstrap';
import './DesksContainer.scss';
import DeskCard from "../DeskCard/DeskCard";
import AppModal from "../AppModal/AppModal";
import { fetchAllDesks, fetchDesksFileteredByGroup } from "../../store/desksSlice";
import { fetchProfiles } from "../../store/profilesSlice";
import { getAllGroups } from "../../store/groupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { createBooking, fetchAllBookings } from "../../store/bookingsSlice";
import { useAuth } from "../../Auth";
import {
    createDateObject,
    formatTime24H,
    getCurrentDate,
    getCurrentTime
} from "../../utils/datetimeUtils"
import { getDeskStatus } from "../../utils/deskCardUtils";
import { getWorkHoursIso, getTimeZone, getWorkHours } from "../../utils/bookingUtils";

const DesksContainer = ({ groupId, bookingOptions, onConfirmBooking, inactive }) => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const scrollContainerRef = useRef(null);
    const previousScrollTop = useRef(0);


    const [showBookingConfirmModal, setShowBookingConfirmModal] = useState(false);
    const [desks, setDesks] = useState([]);
    const [selectedDesks, setSelectedDesks] = useState([]);

    const allGroups = useSelector(getAllGroups);

    const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    useEffect(() => {
        dispatch(fetchAllBookings());
    }, [dispatch]);

    const refreshDesks = useCallback((groupId) => {
        dispatch(groupId ?
            fetchDesksFileteredByGroup({ parentGroupId: groupId, includeBookings: true, includeInChildren: true }) :
            fetchAllDesks({ includeBookings: true })).then((r) => {
                const deskData = JSON.parse(r.payload).data;
                dispatch(fetchProfiles()).then((r) => {
                    const profileData = JSON.parse(r.payload).data;
                    const desksWithProfiles = populateDeskProfiles(deskData, profileData, allGroups);
                    setDesks(desksWithProfiles);
                });
            });
    }, [dispatch, allGroups]);

    useEffect(() => {
        refreshDesks(groupId);
    }, [refreshDesks, groupId]);

    const populateDeskProfiles = (desks, profiles, groups) => {
        let desksWithProfiles = [...desks];

        const findGroupByIdRecursive = (groupId, groupList) => {
            for (const group of groupList) {
                if (group.id === groupId) {
                    return group;
                }
                if (group.childGroups && group.childGroups.length > 0) {
                    const foundGroup = findGroupByIdRecursive(groupId, group.childGroups);
                    if (foundGroup) {
                        return foundGroup;
                    }
                }
            }
            return null;
        };

        const findProfileForGroup = (groupId) => {
            let profile = profiles.find(p => p.groups && p.groups.includes(groupId));

            if (profile) {
                return profile;
            }

            const group = findGroupByIdRecursive(groupId, groups);

            if (group && group.parentGroupId) {
                return findProfileForGroup(group.parentGroupId);
            }

            return profiles.find(p => p.isDeskDefault);
        };

        desksWithProfiles.forEach(desk => {
            if (!desk.parentGroupId) {
                desk.profile = profiles.find(p => p.isDeskDefault);
            } else {
                desk.profile = findProfileForGroup(desk.parentGroupId);
            }
        });

        return desksWithProfiles;
    };

    const getDeskCard = (desk, status) => {
        return <DeskCard
            key={desk.id}
            desk={desk}
            inactive={inactive}
            added={selectedDesks.includes(desk.id)}
            onAddOrRemove={handleAddOrRemoveDesk}
            bookingOptions={bookingOptions}
            status={status}
        />;
    };

    const DesksResult = ({ desksToDisplay }) => {

        // Get desk status.
        let deskStatusArray = desksToDisplay.map((desk) => getDeskStatus(bookingOptions, desk));

        // Sort desks to show available first.
        desksToDisplay.sort((deskA, deskB) => {
            const statusA = deskStatusArray[desksToDisplay.indexOf(deskA)].available;
            const statusB = deskStatusArray[desksToDisplay.indexOf(deskB)].available;

            return statusB - statusA;
        });

        const saveScrollPosition = () => {
            if (scrollContainerRef.current) {
                previousScrollTop.current = scrollContainerRef.current.scrollTop;
            }
        };

        const restoreScrollPosition = () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollTop = previousScrollTop.current;
            }
        };

        useEffect(() => {
            restoreScrollPosition();
        }, [desksToDisplay]);


        return (
            <div className={"page-content"}>
                <div
                    className={"scroll-container"}
                    ref={scrollContainerRef}
                    onScroll={saveScrollPosition}
                >
                    <div className={"card-container"}>
                        <div className={"display-flex-wrap"}>
                            {desksToDisplay.map((desk) => getDeskCard(desk, deskStatusArray[desksToDisplay.indexOf(desk)]))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const handleConfirmBooking = () => {

        selectedDesks.forEach((deskId) => {
            const targetIntervalWeekday = weekdays[createDateObject(bookingOptions.date, bookingOptions.startTime).getDay()];
            const desk = desks.filter((desk) => desk.id === deskId)[0]
            const deskWorkingHours = getWorkHours(desk);

            // Convert HH:mm work hours to ISO formatted based on date and resource time zone
            const workHoursIso = getWorkHoursIso(bookingOptions.date, deskWorkingHours[targetIntervalWeekday], getTimeZone(desk));

            const createRequest = {
                resourceId: deskId,
                startTime: bookingOptions.allDay ?
                    bookingOptions.date === getCurrentDate() ? createDateObject(bookingOptions.date, getCurrentTime(1)) :
                        new Date(workHoursIso.start)
                    : createDateObject(bookingOptions.date, bookingOptions.startTime),
                endTime: bookingOptions.allDay ? new Date(workHoursIso.end)
                    : createDateObject(bookingOptions.date, bookingOptions.endTime),
                checkedIn: true,
                allDay: bookingOptions.allDay,
                organiser: { name: auth.getCurrentUser()?.name, email: auth.getCurrentUser()?.email }
            }
            dispatch(createBooking({
                body: createRequest,
            })).then((r) => {
                refreshDesks();
            })
        });
        setSelectedDesks([]);
        setShowBookingConfirmModal(false);
        onConfirmBooking();
    };

    const handleAddOrRemoveDesk = (id, action) => {
        switch (action) {
            case "add":
                setSelectedDesks(selectedDesks => [...selectedDesks, id]);
                break;
            case "remove":
                setSelectedDesks(selectedDesks => [...selectedDesks].filter((existingId) => existingId !== id));
                break;
            default:
                break;
        }
    }

    const getConfirmModalContent = () => {
        if (bookingOptions.allDay) {
            if (selectedDesks.length === 1) {
                const targetIntervalWeekday = weekdays[createDateObject(bookingOptions.date, bookingOptions.startTime).getDay()];
                const desk = desks.filter((desk) => desk.id === selectedDesks[0])[0]
                const deskWorkingHours = getWorkHours(desk);

                // Convert HH:mm work hours to ISO formatted based on date and resource time zone
                const workHoursIso = getWorkHoursIso(bookingOptions.date, deskWorkingHours[targetIntervalWeekday], getTimeZone(desk));

                var startTime = formatTime24H(bookingOptions.date === getCurrentDate() ? getCurrentTime(1) : new Date(workHoursIso.start));
                var endTime = formatTime24H(new Date(workHoursIso.end));

                return <><p>Are you sure you want to book the selected desk all day on <b>{`${bookingOptions.date}`}</b>?</p><p>
                    The desk will be booked <b>{`from ${startTime} to ${endTime}`}</b>.</p></>;
            }

            const individualBookings = selectedDesks?.map((id) => {

                const desk = desks.find((desk) => desk.id === id);
                const weekday = weekdays[createDateObject(bookingOptions.date, bookingOptions.startTime).getDay()];
                const deskWorkingHours = getWorkHours(desk);

                // Convert HH:mm work hours to ISO formatted based on date and resource time zone
                const workHoursIso = getWorkHoursIso(bookingOptions.date, deskWorkingHours[weekday], getTimeZone(desk));

                var startTime = formatTime24H(bookingOptions.date === getCurrentDate() ? getCurrentTime(1) : new Date(workHoursIso.start));
                var endTime = formatTime24H(new Date(workHoursIso.end));

                return <p><b>{desk.name}</b>: from <b>{startTime}</b> to <b>{endTime}</b></p>
            })

            return <>
                <p>Are you sure you want to book the selected desks all day on <b>{`${bookingOptions.date}`}</b>?</p>
                {bookingOptions.date !== getCurrentDate() ? <p>They will be booked for the totality of their working hours.</p> : <></>}
                <h5>Summary:</h5>
                <div className="all-day-booking-summary">
                    {individualBookings}
                </div>
            </>;


        } else {
            return <p>Are you sure you want to book the selected {selectedDesks.length > 1 ? "desks" : "desk"} <b>{`from ${bookingOptions.startTime} to ${bookingOptions.endTime} on ${bookingOptions.date}`}</b>?</p>;
        }
    }

    return (
        <div className={"rooms-wrapper"}>
            <div className={"page-header"}>
                <div className={"page-title"}>
                    <h1>Desks</h1>
                </div>
                <Button className="confirm-btn btn-round" disabled={desks.length === 0 || selectedDesks.length < 1} onClick={() => setShowBookingConfirmModal(true)}>
                    <span>Confirm Booking</span>
                </Button>
            </div>
            {desks?.length > 0 ?
                <DesksResult desksToDisplay={desks} /> : <></>
            }

            <AppModal
                show={showBookingConfirmModal}
                size="md"
                header={selectedDesks.length === 1 ? "Confirm Booking" : "Confirm Bookings"}
                content={getConfirmModalContent()}
                footer={<>
                    <Button className={"btn-outline"} onClick={() => setShowBookingConfirmModal(false)}>Cancel</Button>
                    <Button className={"btn-solid"} onClick={handleConfirmBooking}>Confirm</Button>
                </>}
            />
        </div>
    );
};


export default DesksContainer;