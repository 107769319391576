import { FC, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './Calendar.scss';
// import Tags from "../Tags/Tags";
import { areDatesOnSameDay, formatAMPM, getMonthName, getMonthNameShort, getWeekday, getWeekdayShort } from "../../utils/datetimeUtils";
import { ViewportContext } from "../../contexts/ViewportContext";
import { Booking, Resource, ViewMode } from "../../types";
import { CalendarCard } from "./CalendarCard";

const AVAILABLE_HOURS = 23; // Number of hour cells shown in Week and Day views.
const CARD_PIXELS_PER_HOUR = 60; // The vertical height per hour in Week and Day views. 
const CARD_MINIMUM_HEIGHT = CARD_PIXELS_PER_HOUR / 4; // Minimum height in pixels of a booking card in Week and Day views.
const CARD_MAXIMUM_HEIGHT = CARD_PIXELS_PER_HOUR * AVAILABLE_HOURS; // Maximum height in pixels of a booking card in Week and Day views.

interface CalendarOptions {
    viewMode: ViewMode,
    bookings: Booking[],
    resources: Resource[],
    selectedDate: Date,
    onOptionSelect: (value: string) => void;
}

interface CellProps {
    bookings: Booking[],
    resources: Resource[],
    weekDates?: Date[],
    currentSelectedDate: Date,
    onOptionSelect: (value: string) => void;
}

function clampCardHeight(cardHeight: number) {
    let clampedHeight = cardHeight;

    if (clampedHeight < CARD_MINIMUM_HEIGHT)
        clampedHeight = CARD_MINIMUM_HEIGHT;

    if (clampedHeight > CARD_MAXIMUM_HEIGHT)
        clampedHeight = CARD_MAXIMUM_HEIGHT;

    return clampedHeight;
};

function findBookingOnDay(bookings: Booking[], day: string): Booking | undefined {
    const targetDate = new Date(day);

    return bookings.find(booking => {
        const { startTime } = booking;
        const bookingDate = new Date(new Date(startTime).toISOString().substr(0, 10));
        return bookingDate.getTime() === targetDate.getTime();
    });
}

const DailyCalendarCells: FC<CellProps> = ({ bookings, resources, currentSelectedDate, onOptionSelect }) => {
    let startingDate: Date = new Date(2023, 3, 10, 1, 0);
    let demoCells: any[] = [];

    let bookingsToday: Booking[] = bookings.filter((booking) => {
        const bookingStartDate = new Date(booking.startTime);

        return currentSelectedDate.getFullYear() === bookingStartDate.getFullYear() &&
            currentSelectedDate.getMonth() === bookingStartDate.getMonth() &&
            currentSelectedDate.getDate() === bookingStartDate.getDate() &&
            !booking.allDay;
    });

    for (let i = 0; i < AVAILABLE_HOURS; i++) {
        let tempDate: Date = new Date(startingDate);
        tempDate.setHours(tempDate.getHours() + i);

        let bookingsThisHour: Booking[] = bookingsToday.filter((booking) => tempDate.getHours() === (new Date(booking.startTime).getHours() + 1));

        demoCells.push(
            <Row className="hour-row calendar-row">
                <Col className="hour-row-label" xs={1}><span>{formatAMPM(tempDate)}</span></Col>
                <Col className="hour-row-content" xs={11}>
                    <Row className="inner-row">
                        {
                            bookingsThisHour.map((booking) => {

                                const startDate = new Date(booking.startTime);
                                const endDate = new Date(booking.endTime);

                                const durationInHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
                                let heightInPixels = clampCardHeight(durationInHours * CARD_PIXELS_PER_HOUR);

                                return <CalendarCard
                                    booking={booking}
                                    viewMode={ViewMode.Day}
                                    height={heightInPixels}
                                    heightOffset={startDate.getMinutes()}
                                    resource={resources.filter((r) => r.id === booking.resourceId)[0]}
                                    onOptionSelect={onOptionSelect}
                                />;
                            })
                        }
                    </Row>
                </Col>
            </Row>
        );
    }

    const allDayBookingsToday = bookings.filter((booking) => areDatesOnSameDay(new Date(booking.startTime), currentSelectedDate) && booking.allDay);

    return <>
        <Row className="all-day-row calendar-row">
            <Col className="all-day-row-label" xs={1}><span>All-day</span></Col>
            <Col className="hour-row-content all-day">{allDayBookingsToday.length > 0 ? allDayBookingsToday.map((booking) => {
                return <CalendarCard booking={booking} viewMode={ViewMode.Day} onOptionSelect={onOptionSelect} resource={resources.filter((resource) => booking.resourceId === resource.id)[0]} />;
            }) : <></>}</Col>
        </Row>
        {demoCells}
    </>;
};

const WeeklyCalendarCells: FC<CellProps> = ({ bookings, resources, weekDates, currentSelectedDate, onOptionSelect }) => {
    let startingDate: Date = new Date(2023, 3, 10, 1, 0);

    let hourlyCells: any[] = [];
    let allDayCells: any[] = [];

    weekDates?.forEach(date => {

        const allDayBookingsToday = bookings.filter((booking) => areDatesOnSameDay(new Date(booking.startTime), date) && booking.allDay);
        allDayCells.push(<Col className="hour-row-content all-day">
            {allDayBookingsToday.length > 0 ? allDayBookingsToday.map((booking) => {
                return <CalendarCard booking={booking} viewMode={ViewMode.Week} onOptionSelect={onOptionSelect} resource={resources.filter((resource) => booking.resourceId === resource.id)[0]} />;
            }) : <></>}
        </Col>);
    });

    for (let i = 0; i < 23; i++) {
        let tempDate: Date = new Date(startingDate);
        tempDate.setHours(tempDate.getHours() + i);
        hourlyCells.push(
            <Row className="hour-row calendar-row">
                <Col className="hour-row-label" xs={1}><span>{formatAMPM(tempDate)}</span></Col>
                {weekDates?.map((date) => {

                    let bookingsThisHour: Booking[] = bookings.filter((booking) => {
                        const startDate = new Date(booking.startTime);
                        return date.getFullYear() === startDate.getFullYear() &&
                            date.getMonth() === startDate.getMonth() &&
                            date.getDate() === startDate.getDate() &&
                            tempDate.getHours() === (startDate.getHours() + 1) &&
                            !booking.allDay;
                    }
                    );

                    if (bookings.length > 0) {
                        return <Col className="hour-row-content">
                            <Row className="inner-row">
                                {bookingsThisHour.map((booking) => {
                                    const startDate = new Date(booking.startTime);
                                    const endDate = new Date(booking.endTime);

                                    const durationInHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
                                    const heightInPixels = clampCardHeight(durationInHours * CARD_PIXELS_PER_HOUR);

                                    return <CalendarCard
                                        height={heightInPixels}
                                        heightOffset={startDate.getMinutes()}
                                        viewMode={ViewMode.Week}
                                        booking={booking}
                                        resource={resources.filter((r) => r.id === booking.resourceId)[0]}
                                        onOptionSelect={onOptionSelect} />;
                                })}
                            </Row>
                        </Col>;
                    } else {
                        return <Col className="hour-row-content"></Col>;
                    }
                })}
            </Row>
        );
    }

    return <>
        <Row className="all-day-row calendar-row">
            <Col className="all-day-row-label" xs={1}><span>All-day</span></Col>
            {allDayCells}
        </Row>
        {hourlyCells}</>;
};


const MonthlyCalendarCells: FC<CellProps> = ({ bookings, resources, currentSelectedDate, onOptionSelect }) => {

    const selectedYear = currentSelectedDate.getFullYear();
    const selectedMonth = currentSelectedDate.getMonth();

    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1);
    const daysToPreviousMonday = (firstDayOfMonth.getDay() + 6) % 7;

    const startDay = new Date(firstDayOfMonth);
    startDay.setDate(firstDayOfMonth.getDate() - daysToPreviousMonday);

    const lastDayOfMonth = new Date(selectedYear, selectedMonth + 1, 0);
    const isLastDaySunday = lastDayOfMonth.getDay() === 0;
    const daysToNextSunday = isLastDaySunday ? 0 : (7 - lastDayOfMonth.getDay());

    // Create an array containing Date objects for each day of the current month, starting from the calculated Monday and ending on a Sunday 
    const datesArray: Date[] = [];
    for (let i = -daysToPreviousMonday; i < lastDayOfMonth.getDate() + (daysToNextSunday); i++) {
        const currentDate = new Date(startDay);
        currentDate.setDate(startDay.getDate() + i + daysToPreviousMonday);
        datesArray.push(currentDate);
    }

    const formattedDays: Date[][] = [];

    for (let i = 0; i < datesArray.length; i += 7) {
        const group = datesArray.slice(i, i + 7);
        formattedDays.push(group);
    }

    let keyCounter: number = 0;
    const calendarRows: any = formattedDays.map((week) => {
        keyCounter++;
        return <Row className="calendar-row" key={week.toString()}>
            {week.map((date) => {

                let day = date.getDate() === 1 ? `1 ${getMonthNameShort(date)}` : date.getDate().toString();

                const bookingsOnDay = bookings.filter((booking) => areDatesOnSameDay(new Date(booking.startTime), date));

                if (bookingsOnDay.length > 0) {
                    const bookings = bookingsOnDay.map((booking) => {
                        return <CalendarCard booking={booking} viewMode={ViewMode.Month} onOptionSelect={onOptionSelect} resource={resources.filter((resource) => booking.resourceId === resource.id)[0]} />;
                    });

                    return (<Col className="calendar-cell month-col" key={`${keyCounter}-${day}`}>
                        <div><span className={`day ${areDatesOnSameDay(date, new Date()) ? "today" : ""}`}>{day}</span></div>
                        {bookings}
                    </Col>);
                } else {
                    return (<Col className="calendar-cell month-col" key={`${keyCounter}-${date.toISOString()}`}><div><span className={`day ${areDatesOnSameDay(date, new Date()) ? "today" : ""}`}>{day}</span></div></Col>);
                }
            })}
        </Row>;
    });

    return calendarRows;
};

// TODO: Add support for any date. Account for leap year.
const MonthlyCalendarCellsMobile: FC<CellProps> = ({ bookings }) => {

    // This is all static data. Will be changed. 
    const rawDemoDays: number[] = [
        ...Array.from({ length: 6 }, (_, index) => 31 - 5 + index),
        ...Array.from({ length: 30 }, (_, index) => index + 1),
        ...Array.from({ length: 6 }, (_, index) => index + 1)
    ];

    const demoDays: string[] = rawDemoDays.map((day) => {
        return day.toString();
    });

    const formattedDays: string[][] = [];

    for (let i = 0; i < demoDays.length; i += 7) {
        const group = demoDays.slice(i, i + 7);
        formattedDays.push(group);
    }

    let keyCounter: number = 0;
    const calendarRows: any = formattedDays.map((week) => {
        keyCounter++;
        return <Row className={"date-row calendar-row"} key={week.toString()}>
            {week.map((day) => {
                const dayToCheck = `2023-04-${day}`;
                const bookingOnDay = findBookingOnDay(bookings, dayToCheck);

                if (bookingOnDay) {
                    return (<Col className={`date ${day === "10" ? "selected" : ""}`} key={`${keyCounter}-${day}`}>
                        <div><span className={"day"}>{day}</span></div>
                        <div className="dot desk"><span></span></div>
                    </Col>);
                } else {
                    return (<Col className={"date"} key={`${keyCounter}-${day}`}><div><span className={"day"}>{day}</span></div></Col>);
                }
            })}
        </Row>;
    });

    return calendarRows;
};

const GetDailyView = (bookings: Booking[], resources: Resource[], currentSelectedDate: Date, onOptionSelect: (value: string) => void) => {
    return <Container className="calendar-wrapper daily-view" fluid>
        <Row className={"header-row calendar-row"}>
            <h1>{currentSelectedDate?.getDate()} {getMonthName(currentSelectedDate)} <span>{currentSelectedDate?.getFullYear()}</span></h1>
        </Row>
        <Row className="weekday-row calendar-row">
            <Col className="weekday"><span>{getWeekday(currentSelectedDate)}</span></Col>
        </Row>
        <DailyCalendarCells bookings={bookings} resources={resources} currentSelectedDate={currentSelectedDate} onOptionSelect={onOptionSelect} />
    </Container>;
};

const GetWeeklyView = (bookings: Booking[], resources: Resource[], currentSelectedDate: Date, onOptionSelect: (value: string) => void) => {

    const currentDayOfWeek = currentSelectedDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    // Calculate the difference between today and the last Monday
    const daysUntilLastMonday = currentDayOfWeek === 1 ? 0 : (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1);
    const lastMonday = new Date(currentSelectedDate.getTime() - daysUntilLastMonday * 24 * 60 * 60 * 1000);

    // Generate an array of Date objects for the next 7 days
    const weekDates: any = [];
    const weekDatesHeader: any = [];
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(lastMonday.getTime() + i * 24 * 60 * 60 * 1000); // Increment from last Monday
        weekDates.push(currentDate);
        weekDatesHeader.push(<Col className="weekday">
            <span className={`${areDatesOnSameDay(currentDate, new Date()) ? "today" : ""}`}>{`${getWeekdayShort(currentDate)} ${currentDate.getDate()}`}</span>
        </Col >);
    }

    let header = <></>;

    const firstDate = weekDates[0];
    const lastDate = weekDates[weekDates.length - 1];

    // If the week starts and ends on... 
    if (firstDate.getMonth() === lastDate.getMonth()) {
        // ...the same month then show header as DD-DD Month YYYY (ex. 22-28 January 2024)
        header = <h1>{firstDate.getDate()}-{lastDate.getDate()} {getMonthName(firstDate)} <span>{lastDate.getFullYear()}</span></h1>;
    } else if (firstDate.getFullYear() === lastDate.getFullYear()) {
        // ...different months of the same year then show header as DD Month - DD Month YYYY (ex. 29 January - 4 February 2024)
        header = <h1>{firstDate.getDate()} {getMonthName(firstDate)} - {lastDate.getDate()} {getMonthName(lastDate)} <span>{lastDate.getFullYear()}</span></h1>;
    } else {
        // ...different months of different years then show header as DD Month YYYY - DD Month YYYY (30 December 2024 - 5 January 2025)
        header = <h1>{firstDate.getDate()} {getMonthName(firstDate)} <span>{firstDate.getFullYear()}</span> - {lastDate.getDate()} {getMonthName(lastDate)} <span>{lastDate.getFullYear()}</span></h1>;
    }

    return <Container className="calendar-wrapper weekly-view" fluid>
        <Row className={"header-row calendar-row"}>
            {header}
        </Row>
        <Row className="weekday-row calendar-row">
            <Col xs={1}></Col>
            {weekDatesHeader}
        </Row>
        <WeeklyCalendarCells bookings={bookings} resources={resources} currentSelectedDate={currentSelectedDate} weekDates={weekDates} onOptionSelect={onOptionSelect} />
    </Container>;
};

const GetMonthlyView = (bookings: Booking[], resources: Resource[], currentSelectedDate: Date, onOptionSelect: (value: string) => void) => {
    return <Container className="calendar-wrapper" fluid>
        <Row className={"header-row calendar-row"}>
            <h1>{getMonthName(currentSelectedDate)} <span>{currentSelectedDate.getFullYear()}</span></h1>
        </Row>
        <Row className="weekday-row calendar-row">
            <Col className="weekday"><span>Mon</span></Col>
            <Col className="weekday"><span>Tue</span></Col>
            <Col className="weekday"><span>Wed</span></Col>
            <Col className="weekday"><span>Thu</span></Col>
            <Col className="weekday"><span>Fri</span></Col>
            <Col className="weekday"><span>Sat</span></Col>
            <Col className="weekday"><span>Sun</span></Col>
        </Row>
        <MonthlyCalendarCells bookings={bookings} resources={resources} currentSelectedDate={currentSelectedDate} onOptionSelect={onOptionSelect} />
    </Container>;
};

const GetDailyMobileView = (bookings: Booking[], resources: Resource[], currentSelectedDate: Date, onOptionSelect: (value: string) => void) => {
    return <Container className="calendar-wrapper mobile daily-view" fluid>
        <Row className="weekday-row calendar-row">
            <Col className="weekday"><span>M</span></Col>
            <Col className="weekday"><span>T</span></Col>
            <Col className="weekday"><span>W</span></Col>
            <Col className="weekday"><span>T</span></Col>
            <Col className="weekday"><span>F</span></Col>
            <Col className="weekday"><span>S</span></Col>
            <Col className="weekday"><span>S</span></Col>
        </Row>
        <hr />
        <Row className="weekday-row calendar-row">
            <Col className="weekday"><span className="selected">10</span></Col>
            <Col className="weekday"><span>11</span></Col>
            <Col className="weekday"><span>12</span></Col>
            <Col className="weekday"><span>13</span></Col>
            <Col className="weekday"><span>14</span></Col>
            <Col className="weekday"><span>15</span></Col>
            <Col className="weekday"><span>16</span></Col>
        </Row>
        <Row className="all-day-row calendar-row">
            <Col className="all-day-row-label" xs={1}><span>All-day</span></Col>
            <Col className="all-day-row-content" xs={11}></Col>
        </Row>
        <DailyCalendarCells bookings={bookings} resources={resources} currentSelectedDate={currentSelectedDate} onOptionSelect={onOptionSelect} />
    </Container>;
};
const GetWeeklyMobileView = (bookings: Booking[], resources: Resource[], currentSelectedDate: Date, onOptionSelect: (value: string) => void) => {
    return <Container className="calendar-wrapper mobile weekly-view" fluid>
        <Row className="weekday-row calendar-row">
            <Col xs={1}></Col>
            <Col className="weekday"><span>Mon <span className="selected">10</span></span></Col>
            <Col className="weekday"><span>Tue 11</span></Col>
            <Col className="weekday"><span>Wed 12</span></Col>
            <Col className="weekday"><span>Thu 13</span></Col>
            <Col className="weekday"><span>Fri 14</span></Col>
            <Col className="weekday"><span>Sat 15</span></Col>
            <Col className="weekday"><span>Sun 16</span></Col>
        </Row>
        <Row className="all-day-row calendar-row">
            <Col className="all-day-row-label" xs={1}><span>All-day</span></Col>
            <Col className="hour-row-content"></Col>
            <Col className="hour-row-content"></Col>
            <Col className="hour-row-content"></Col>
            <Col className="hour-row-content"></Col>
            <Col className="hour-row-content"></Col>
            <Col className="hour-row-content"></Col>
            <Col className="hour-row-content"></Col>
        </Row>
        <WeeklyCalendarCells bookings={bookings} resources={resources} currentSelectedDate={currentSelectedDate} weekDates={[]} onOptionSelect={onOptionSelect} />
    </Container>;
};
const GetMonthlyMobileView = (bookings: Booking[], resources: Resource[], currentSelectedDate: Date, onOptionSelect: (value: string) => void) => {
    return <Container className="calendar-wrapper monthly-view mobile" fluid>
        <Row className="weekday-row calendar-row">
            <Col className="weekday"><span>Sun</span></Col>
            <Col className="weekday"><span>Mon</span></Col>
            <Col className="weekday"><span>Tue</span></Col>
            <Col className="weekday"><span>Wed</span></Col>
            <Col className="weekday"><span>Thu</span></Col>
            <Col className="weekday"><span>Fri</span></Col>
            <Col className="weekday"><span>Sat</span></Col>
        </Row>
        <hr />
        <MonthlyCalendarCellsMobile bookings={bookings} resources={resources} currentSelectedDate={currentSelectedDate} onOptionSelect={onOptionSelect} />
    </Container>;
};

const Calendar: FC<CalendarOptions> = ({ viewMode, bookings, resources, selectedDate, onOptionSelect }) => {

    const { isMobile } = useContext(ViewportContext);

    let view: JSX.Element;

    if (isMobile) {
        switch (viewMode) {
            case ViewMode.Day:
                view = GetDailyMobileView(bookings, resources, selectedDate, onOptionSelect);
                break;
            case ViewMode.Week:
                view = GetWeeklyMobileView(bookings, resources, selectedDate, onOptionSelect);
                break;
            case ViewMode.Month:
                view = GetMonthlyMobileView(bookings, resources, selectedDate, onOptionSelect);
                break;
            default:
                view = GetMonthlyMobileView(bookings, resources, selectedDate, onOptionSelect);
                break;
        }

        return <>
            {view}
        </>;
    } else {
        switch (viewMode) {
            case ViewMode.Day:
                return GetDailyView(bookings, resources, selectedDate, onOptionSelect);
            case ViewMode.Week:
                return GetWeeklyView(bookings, resources, selectedDate, onOptionSelect);
            case ViewMode.Month:
                return GetMonthlyView(bookings, resources, selectedDate, onOptionSelect);
            default:
                return GetMonthlyView(bookings, resources, selectedDate, onOptionSelect);
        }
    }

};

export default Calendar;