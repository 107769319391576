import "./Main.scss";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { ViewportContext } from "../../contexts/ViewportContext";
import BottomNav from "../BottomNav/BottomNav";

function Main() {

    const { isMobile } = useContext(ViewportContext);

    return (
        isMobile ? <div className="App">
            <Header showHelp={true} />
            <main>
                <div className={"main-content mobile"}>
                    <Outlet />
                </div>
                <BottomNav />
            </main>
        </div>
            :
            <div className="App">
                <Header showHelp={true} />
                <main>
                    <div>
                        <SideNav />
                    </div>

                    <div className={"main-content"}>
                        <Outlet />
                    </div>
                </main>
            </div>
    );
}

export default Main;
