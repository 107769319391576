import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrl } from '../AppSlice';

const initialState = {
    isMobileDrawerOpen: false,
    version: '',
    tokenStatus: ''
};

export const fetchBuildVersion = createAsyncThunk(`${apiUrl}/version`,
    async (params, { rejectWithValue }) => {
        const response = await fetch(`${apiUrl}/version`)

        const resolvedResponse = await response;
        if (resolvedResponse.status === 401) {
            return rejectWithValue({ status: resolvedResponse.status.toString() });
        }
        return await resolvedResponse.text();
    }
)

export const versionSlice = createSlice({
    name: 'version',
    initialState,
    reducers: {
        toggle: state => {
            state.isMobileDrawerOpen = !state.isMobileDrawerOpen
        }
    },
    extraReducers: {
        [fetchBuildVersion.fulfilled.toString()]: (state, action) => {
            if (action.payload.length > 0) {
                state.version = action.payload;
            }
        },
        [fetchBuildVersion.rejected.toString()]: (state, action) => {
            if (action.payload && action.payload.status) {
                state.tokenStatus = action.payload.status;
            }
        },
    }
});

export const { toggle } = versionSlice.actions;

export const getBuildVersion = (state) => state.version.version;
export const selectMobileDrawerState = (state) => state.version.isMobileDrawerOpen;

export default versionSlice.reducer;
